import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"


// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

import SearchFilter from "../../components/parts/chart/search-filter"
import EquipmentCosts from "../../components/parts/chart/equipment-costs"
import EquipmentCostsFuture from "../../components/parts/chart/equipment-costs-future"
import PowerGenerationCosts from "../../components/parts/chart/power-generation-costs"
import PowerGenerationCostsFuture from "../../components/parts/chart/power-generation-costs-future"
import MakerShare from "../../components/parts/chart/maker-share"
import Patent from "../../components/parts/chart/patent"
import BubbleChart from "../../components/parts/chart/bubble-chart"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.searchInit = {
      filter1Init: 0,
      filter2Init: 0,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <EquipmentCosts id="equipmentCostLand" chartData="windLand" sourceHide="true" width="100%" height="420px" />
              <EquipmentCosts id="equipmentCostOffshore" chartData="windOffshore" titleHide="true" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <PowerGenerationCosts id="powerGenerationCostsLand" chartData="windLand" sourceHide="true" width="100%" height="420px" />
              <PowerGenerationCosts id="powerGenerationCostsOffshore" chartData="windOffshore" titleHide="true" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <EquipmentCostsFuture id="equipmentCostsFutureLand" chartData="windLand" sourceHide="true" width="100%" height="420px" />
              <EquipmentCostsFuture id="equipmentCostsFutureOffshore" chartData="windOffshore" titleHide="true" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <PowerGenerationCostsFuture id="powerGenerationCostsFutureLand" chartData="windLand" sourceHide="true" width="100%" height="420px" />
              <PowerGenerationCostsFuture id="powerGenerationCostsFutureOffshore" chartData="windOffshore" titleHide="true" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BubbleChart id="windCompetitiveness" chartData="windCompetitiveness" defaultItem="land" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BubbleChart id="windElementTech" chartData="windElementTech" defaultItem="bearing" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <MakerShare id="makerShareLand" chartData="windLand" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <MakerShare id="makerShareOffshore" chartData="windOffshore" width="100%" height="420px" />
            </div>
          </div>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <Patent id="patent" chartData="wind" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`